import { Recipe, RecipeType } from '@/api/types';

export type AddonsType = RecipeType.SNACK | RecipeType.SUPPLEMENT;

export interface AddonConfig {
  title: string;
  subtitle: { [key: string]: string };
  type: RecipeType;
  unit: 'bag' | 'pack';
  description: DescriptionForAddons;
}

interface DescriptionForAddons {
  forShopModal: {
    [key: string]: string;
  };
}

/**
 * Some of this should be moved to the BE at some point
 */
export const ADDONS_CONFIGURATION: Record<AddonsType, AddonConfig> = {
  [RecipeType.SUPPLEMENT]: {
    title: 'Supplements',
    subtitle: {},
    type: RecipeType.SUPPLEMENT,
    unit: 'pack',
    description: {
      forShopModal: {
        'SUPP-AI': 'Promotes healthy skin and a strong immune response',
        'SUPP-C': 'Helps regulate cortisol for a calm disposition',
        'SUPP-HJ': 'Supports joint mobility in dogs of all ages',
      },
    },
  },
  [RecipeType.SNACK]: {
    title: 'Snacks',
    subtitle: {
      'SN-CHICKENBITES': '2.5 oz per bag; approx. 23 treats',
      'SN-SPBITES': '3 oz per bag; approx. 28 treats',
      'SN-BISONBITES': '2.5 oz per bag; approx. 85 treats',
      'SN-BEEFTRAINERS': '2.5 oz per bag; approx. 250 treats',
      'SN-TURKEYSTRIPS': '3.5 oz per bag; approx. 12 treats',
    },
    type: RecipeType.SNACK,
    unit: 'bag',
    description: {
      forShopModal: {
        'SN-BISONBITES': '100% Bison Kidney',
        'SN-CHICKENBITES': '100% Chicken Breast',
        'SN-SPBITES': '100% Sweet Potato',
        'SN-BEEFTRAINERS': '100% Beef Liver',
      },
    },
  },
};

export type RecipeCardType = RecipeType.UNKIBBLE | RecipeType.FRESH | 'other';
export type RecipeCardBanner = { funnel?: string; portal?: string };

export const RecipeCardInfoPillWithStar = 'Best Seller';

export const RecipeCardBanners: Record<Recipe['id'], RecipeCardBanner> = {
  'U-B': {
    funnel: 'RECOMMENDED',
    portal: 'RECOMMENDED',
  },
  'U-T': {
    funnel: 'NEW RECIPE',
  },
  'F-T': {
    funnel: 'RECOMMENDED',
    portal: 'RECOMMENDED',
  },
};

export const ADDONS_FREE_FOR_LIFE_CODES = [
  'SNACK-SPBITES-1PK-R-FREE',
  'SNACK-BISONBITES-1PK-R-FREE',
  'SNACK-CHICKENBITES-1PK-R-FREE',
];

export const NON_CUSTOMER_FACING_PRODUCT_CODES = [
  'COL-REFORMULATION-FRESH',
  'COL-REFORMULATION-UNKIBBLE',
];

export const LINK_TO_WEB1 = 'https://app.spotandtango.com/login';
export const LINK_TO_WEB2 = 'https://shop.spotandtango.com/account/login';
export const ASSETS_URL = 'https://assets.spotandtango.com';
export const WTP_URL = 'https://whatthepup.spotandtango.com/';
export const SNT_HOMEPAGE_URL = 'https://spotandtango.com';
export const HOW_TO_CONTACT_SUPPORT_URL =
  'https://spotandtango.com/faq?a=How-do-I-contact-customer-support---id--IKoHhdLiR3SYrMUbeS90EQ';
export const FRIENDBUY_MERCHANT_ID = '19dddc88-1f0b-4624-9952-32b86c5756cf';
